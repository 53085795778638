import useSWR from 'swr'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import { useState, useRef, useEffect, forwardRef, useContext } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useOverlay, useModal, OverlayContainer } from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { useIsSSR } from '@react-aria/ssr'
import { mergeProps } from '@react-aria/utils'
import {
  DISPLAY_APP_BANNER,
  DISPLAY_APP_BANNER_PARTNER_EXCLUSION,
  DISPLAY_BANNER,
  MAINTENANCE,
  WHATSAPP_URL,
  isXMAS,
  useFilteredConstants,
} from './constants'

import styles from './Navigation.module.css'

// Hooks
import useUser from '@/hooks/user'
import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'
import useCurrency from '@/hooks/currency'
import usePrefersReducedMotion from '@/hooks/prefers-reduced-motion'
import useScroll from '@/hooks/scroll'
import useTrips from '@/hooks/trips'
import useDates from '@/hooks/dates'
import useBreakpoints from '@/hooks/breakpoints'

// Icons
import Arrow from '@/assets/svg/arrow.svg'
import FullLogo from '@/assets/svg/full-logo.svg'
import Plane from '@/assets/svg/plane-outline.svg'
import Ticket from '@/assets/svg/ticket.svg'
import Infos from '@/assets/svg/infos.svg'
import Profiles from '@/assets/svg/profiles.svg'
import BackArrow from '@/assets/svg/back-arrow.svg'
import Checks from '@/assets/svg/checks.svg'
import ChecksConnect from '@/assets/svg/credit-card.svg'
import Present from '@/assets/svg/present.svg'
import Buoy from '@/assets/svg/buoy.svg'
import Coronactu from '@/assets/svg/coronactu.svg'
import Coin from '@/assets/svg/coin.svg'
import SmallCoin from '@/assets/svg/coin-sm.svg'
import Whatsapp from '@/assets/svg/whatsapp.svg'
import UserPlaceholder from '@/assets/img/user-placeholder.png'
import PlaneTail from '@/assets/img/plane-tail.png'
import LugageSm from '@/assets/img/lugage-sm.png'

// Logic
import api from '@/services/api'

// Components
import OrganiseTrip from '@/v2/components/nav/OrganiseTrip'
import Select from '@/components/ui/select'
import Button from '@/components/ui/button'
import LoginComponent from '@/components/auth/Login'
import RegisterComponent from '@/components/auth/Register'
import GoogleAuth from '@/components/auth/GoogleAuth'
import Trans from '@/components/i18n/Trans'
import DropDown from '@/components/ui/popover/drop-down'
import DownloadAppBanner from '@/components/downloadapp/DownloadAppBanner'
import { getPartnershipsCookie } from '@/utils/cookies'
import TrustpilotScore from '@/components/TrustpilotScore'
import Checkbox from './ui/checkbox'
import { ExperimentsContext } from '@/providers/experiments'
import Banner from './Banner'
import CarBanner from './cars/CarBanner'
import ChristmasBanner from './ChristmasBanner'

function MenuItem({ children, icon, url = '/' }) {
  return (
    <Button
      href={url}
      unstyled
      className="block p-20 mb-15 md:mb-30 bg-white text-dark rounded ring-1 ring-dark-20 shadow-thin text-14 motion-safe:transition motion-safe:duration-150 hover:shadow-md hover:ring-2 hover:ring-primary hover:text-primary"
    >
      <div className="opacity-60 mb-15 h-25">{icon}</div>
      {children}
    </Button>
  )
}

const Trigger = forwardRef(function TriggerComponent(
  { onClick, userNavToggle, mode = 'dark', ...rest },
  ref
) {
  const { t } = useTranslation()
  const router = useRouter()
  const { user, isFlightClub } = useUser()
  const textColor = mode == 'dark' ? 'text-dark' : 'text-white'
  const borderColor = mode === 'dark' ? 'ring-dark-20' : 'ring-white-50'
  const bgColor = mode === 'dark' ? 'bg-dark-20' : 'bg-white'

  return (
    <div className="relative inline-block z-20">
      <div
        className={clsx(
          'rounded-full flex h-40 relative ring-1 motion-safe:transition motion-safe:duration-300 ease-out',
          borderColor
        )}
      >
        <Button
          {...mergeProps({ onClick, ref }, rest)}
          unstyled
          className="flex items-center relative rounded-full motion-safe:transition motion-safe:duration-300 ease-out"
        >
          {user ? (
            <>
              <div className="rounded-full overflow-hidden w-40 h-40">
                <Image
                  src={UserPlaceholder}
                  alt=""
                  quality={100}
                  placeholder="blur"
                />
              </div>
              {router.route === '/' && user.first_name && (
                <p
                  className={clsx(
                    textColor,
                    'hidden md:block font-bold text-14 leading-none px-15 border-r border-white-20 text-left'
                  )}
                >
                  {t('components.navigation.helloName', {
                    name: user.first_name,
                  })}
                  {isFlightClub && (
                    <>
                      <br />
                      <small
                        className={
                          mode === 'dark' ? 'text-dark-80' : 'text-white-80'
                        }
                      >
                        {t('components.navigation.flightClubMember')}
                      </small>
                    </>
                  )}
                </p>
              )}
            </>
          ) : (
            <div className="h-40 flex items-center justify-center"></div>
          )}
          <div className="flex flex-col justify-center w-20 h-15 mx-15">
            {userNavToggle ? (
              <>
                <div
                  className={clsx(
                    bgColor,
                    'rounded-full w-full h-2 transform origin-center -rotate-45'
                  )}
                ></div>
                <div
                  className={clsx(
                    bgColor,
                    'rounded-full w-full h-2 -mt-2 transform origin-center rotate-45'
                  )}
                ></div>
              </>
            ) : (
              <>
                <div className={clsx('rounded-full w-full h-2', bgColor)}></div>
                <div
                  className={clsx('rounded-full w-full h-2 my-3', bgColor)}
                ></div>
                <div className={clsx('rounded-full w-full h-2', bgColor)}></div>
              </>
            )}
          </div>
        </Button>
      </div>
    </div>
  )
})

function Menu({
  userNavToggle,
  setUserNavToggle,
  display,
  setDisplay,
  disconnect,
  connect,
  triggerRef,
  nextTrip,
  nextSlice,
}) {
  const { t } = useTranslation()
  const router = useRouter()
  const { formatCurrency } = useCurrency()

  const { user, admin } = useUser()
  const { data: userCredit } = useSWR(user ? '/credits/me' : null)
  const { isFlagActivated } = useContext(ExperimentsContext)

  const triggerDimensions = triggerRef?.current?.getBoundingClientRect()

  const [triggerTop, setTriggerTop] = useState(triggerDimensions?.top)
  const [triggerRight, setTriggerRight] = useState(
    triggerDimensions?.right
      ? window.innerWidth - triggerDimensions?.right
      : undefined
  )
  const prefersReducedMotion = usePrefersReducedMotion()
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = useBreakpoints()

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const onClose = () => {
    setIsOpen(false)

    if (prefersReducedMotion) {
      setUserNavToggle(false)
    } else {
      setTimeout(() => {
        setUserNavToggle(false)
      }, 200)
    }
  }

  const ref = useRef()
  const { overlayProps, underlayProps } = useOverlay(
    { isOpen: userNavToggle, onClose, isDismissable: true },
    ref
  )

  const { modalProps } = useModal()

  const { dialogProps, titleProps } = useDialog({}, ref)

  useEffect(() => {
    if (
      triggerTop !== triggerDimensions?.top ||
      triggerRight !== triggerDimensions?.right
    ) {
      setTriggerTop(triggerDimensions?.top)
      setTriggerRight(window.innerWidth - triggerDimensions?.right)
    }
  }, [triggerDimensions?.top, triggerDimensions?.right])

  useEffect(() => {
    const onResize = () => {
      const newDimensions = triggerRef?.current?.getBoundingClientRect()
      setTriggerTop(newDimensions?.top)
      setTriggerRight(window.innerWidth - newDimensions?.right)
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return (
    // Panel
    <div className={`relative z-20 ${userNavToggle ? 'block' : 'hidden'}`}>
      <div
        className={clsx(
          'fixed top-0 right-0 bottom-0 left-0 motion-safe:transition-colors',
          styles.overlayClose,
          { [styles.overlayOpen]: isOpen }
        )}
        {...underlayProps}
      >
        <FocusScope contain restoreFocus autoFocus>
          <div
            className={clsx(
              'static md:fixed h-app top-0 right-0 bottom-0 md:w-2/3 shadow-double overflow-auto transform bg-white motion-safe:transition-all',
              styles.menuClose,
              {
                [styles.menuOpen]: isOpen,
              }
            )}
            ref={ref}
            {...mergeProps(overlayProps, dialogProps, modalProps)}
          >
            <div
              className="absolute"
              style={{
                top: 20,
                right: triggerRight,
              }}
            >
              <Trigger
                onClick={onClose}
                userNavToggle={userNavToggle}
                aria-label={t('components.navigation.closeMenu')}
                mode={
                  (router.route == '/' || router.route == '/conciergerie') &&
                  (!user || !userNavToggle)
                    ? 'white'
                    : 'dark'
                }
              />
            </div>
            {/* Auth */}
            {!user ? (
              <section className={`pt-30 px-30 pb-30 ${styles.auth}`}>
                {display === 'register' || display === 'login' ? (
                  <div className="w-1/2 absolute right-0 top-0 bottom-0">
                    <Image
                      src={PlaneTail}
                      alt=""
                      layout="fill"
                      objectFit="contain"
                      objectPosition="top"
                    />
                  </div>
                ) : null}
                <div className={`relative ${styles.panelContainer}`}>
                  {display === 'register' ? (
                    <div>
                      <Button
                        className="flex items-center text-14 mb-30"
                        onClick={() => setDisplay(null)}
                        unstyled
                      >
                        <BackArrow className="mr-10" />
                        {t('components.navigation.back')}
                      </Button>
                      <p className="title-2 mb-20 w-3/4">
                        {t('components.navigation.createYourAccount')}
                      </p>
                      <section>
                        <div className="row">
                          <div className="lg:col-6">
                            <GoogleAuth onSuccess={connect} />
                          </div>
                        </div>
                        <p className="opacity-80 mt-20 mb-15">
                          {t('components.navigation.byEmail')}
                        </p>
                        <RegisterComponent
                          hasBorder={false}
                          onSuccess={connect}
                        />
                      </section>
                      <div className="flex flex-wrap items-baseline mt-20">
                        <span className="mr-5">
                          {t('components.navigation.existingAccount')}
                        </span>
                        <Button
                          plain
                          onClick={() => setDisplay('login')}
                          trackingEventName="website_display_login_clicked"
                        >
                          {t('components.navigation.login')}
                        </Button>
                      </div>
                    </div>
                  ) : display === 'login' ? (
                    <div>
                      <Button
                        className="flex items-center text-14 mb-30"
                        onClick={() => setDisplay(null)}
                        unstyled
                      >
                        <BackArrow className="mr-10" />
                        {t('components.navigation.back')}
                      </Button>
                      <p className="title-2 mb-20">
                        {t('components.navigation.login2')}
                      </p>
                      <section className="row">
                        <div className="lg:col-6">
                          <GoogleAuth onSuccess={connect} />
                          <p className="mt-20 mb-15">
                            {t('components.navigation.byEmail')}
                          </p>
                          <LoginComponent
                            hasBorder={false}
                            onSuccess={connect}
                          />
                        </div>
                      </section>
                      <div className="flex flex-wrap items-baseline mt-20">
                        <span className="mr-5">
                          {t('components.navigation.noAccount')}
                        </span>
                        <Button
                          plain
                          color="primary"
                          onClick={() => setDisplay('register')}
                          trackingEventName="website_display_register_clicked"
                        >
                          {t('components.navigation.createAccount')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="title-2 mb-15">
                        <Trans
                          i18nKey="components.navigation.hello"
                          components={{
                            name: <span className="block text-primary" />,
                          }}
                        />
                      </p>
                      <div className="flex flex-col md:flex-row items-center">
                        <GoogleAuth onSuccess={connect} />
                        <p className="hidden md:block mx-20">
                          {t('components.navigation.or')}
                        </p>
                        <Button
                          onClick={() => setDisplay('login')}
                          trackingEventName="website_display_login_clicked"
                          color="white"
                          size="xl"
                          className="w-full md:mt-0 mt-15"
                        >
                          {t('components.navigation.login')}
                        </Button>
                      </div>
                      <div className="flex flex-wrap items-baseline mt-20">
                        <span className="mr-5">
                          {t('components.navigation.noAccount')}
                        </span>
                        <Button
                          plain
                          color="primary"
                          onClick={() => setDisplay('register')}
                          trackingEventName="website_display_register_clicked"
                        >
                          {t('components.navigation.createAccount')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            ) : null}
            {/* Menu */}
            <section className="pt-30 px-30 pb-70">
              <div className={styles.panelContainer}>
                {user ? (
                  <>
                    <div className="mb-15">
                      <p className="title-2" {...titleProps}>
                        <Trans
                          i18nKey={
                            user.first_name
                              ? 'components.navigation.ciaoName'
                              : 'components.navigation.ciao'
                          }
                          components={{
                            name: (
                              <span className="block text-primary break-all" />
                            ),
                          }}
                          values={{ name: user.first_name }}
                        />
                      </p>
                      {userCredit ? (
                        <div className="flex items-center text-primary mt-5 mb-10">
                          <SmallCoin className="mr-10" />
                          <span className="font-bold text-14">
                            {t('components.navigation.credits', {
                              amount: formatCurrency(
                                userCredit?.data?.balance_amount,
                                userCredit?.data?.balance_currency
                              ),
                            })}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {nextTrip && isMobile ? (
                      <div className="my-30">
                        <NextTrip trip={nextTrip} slice={nextSlice} />
                      </div>
                    ) : null}
                    <section>
                      <p className="title-5 mb-15">
                        {t('components.navigation.myAccount')}
                      </p>
                      <div className="row">
                        <div className="md:col-3">
                          <MenuItem url="/account" icon={<Infos />}>
                            {t('components.navigation.personalInfo')}
                          </MenuItem>
                        </div>
                        <div className="md:col-3">
                          <MenuItem url="/travelers" icon={<Profiles />}>
                            {t('components.navigation.travelerProfiles')}
                          </MenuItem>
                        </div>
                      </div>
                    </section>
                  </>
                ) : null}
                <section>
                  <p className="title-5 mb-15">
                    {t('components.navigation.trips')}
                  </p>
                  <div className="row">
                    <div className="md:col-3">
                      <MenuItem url="/" icon={<Ticket />}>
                        {t('components.navigation.book')}
                      </MenuItem>
                    </div>
                    <div className="md:col-3">
                      <MenuItem
                        url={user ? '/trips' : '/login'}
                        icon={<Plane />}
                      >
                        {t('components.navigation.myTrips')}
                      </MenuItem>
                    </div>
                  </div>
                </section>
                <section>
                  <p className="title-5 mb-15">
                    {t('components.navigation.superPowers')}
                  </p>
                  <div className="row">
                    {router.locale === 'fr' && (
                      <>
                        <div className="md:col-3">
                          <MenuItem url="/ancv" icon={<Checks />}>
                            {t('components.navigation.checks')}
                          </MenuItem>
                        </div>

                        <div className="md:col-3">
                          <MenuItem
                            url="/ancv-connect"
                            icon={<ChecksConnect />}
                          >
                            {t('components.navigation.checksConnect')}
                          </MenuItem>
                        </div>
                      </>
                    )}
                    <div className="md:col-3">
                      <MenuItem
                        url="https://ulysse.com/news"
                        icon={<Coronactu />}
                      >
                        Travelnews
                      </MenuItem>
                    </div>
                    <div className="md:col-3">
                      <MenuItem url="/gift" icon={<Present />}>
                        {t('components.navigation.giftCards')}
                      </MenuItem>
                    </div>

                    <div className="md:col-3">
                      <MenuItem url="/referral" icon={<Coin />}>
                        {t('components.navigation.referral')}
                      </MenuItem>
                    </div>
                    <div className="md:col-3">
                      <MenuItem url={t('urls.help')} icon={<Buoy />}>
                        {t('components.navigation.help')}
                      </MenuItem>
                    </div>
                  </div>
                </section>
                {isFlagActivated('front_super_powers') && admin.is && user && (
                  <section className="pb-70">
                    <p className="title-5 mb-15">Admin</p>
                    <div className="flex flex-col gap-10">
                      {isFlagActivated('show_ranking_scores') && (
                        <Checkbox
                          label="Show scores on result page"
                          value={admin.powers['scores'] === true}
                          onChange={(val) => admin.setPower('scores', val)}
                        />
                      )}
                      <Checkbox
                        label="Show suppliers on result page"
                        value={admin.powers['suppliers'] === true}
                        onChange={(val) => admin.setPower('suppliers', val)}
                      />
                    </div>
                  </section>
                )}
                <section className="py-20 flex items-center border-t border-b border-dark-20">
                  <div className="flex-1 mr-20">
                    <Select
                      id="locale-select"
                      label={t('components.navigation.language')}
                      options={router.locales.map((L) => ({
                        value: L,
                        label: t(`components.navigation.locales.${L}`),
                      }))}
                      value={router.locale}
                      onChange={(e) => {
                        router.push(
                          { pathname: router.pathname, query: router.query },
                          router.pathname,
                          {
                            locale: e.target.value,
                          }
                        )
                      }}
                    />
                  </div>
                  <div className="flex-1" />
                </section>
                {user ? (
                  <Button plain onClick={disconnect} className="mt-20">
                    {t('components.navigation.logout')}
                  </Button>
                ) : null}
              </div>
            </section>
          </div>
        </FocusScope>
      </div>
    </div>
  )
}

function NavigationComponent({
  className = '',
  isDark = false,
  showTrustpilot,
  children,
}) {
  const router = useRouter()
  const { track } = useAnalytics()
  const { user, mutateUser, isFlightClub } = useUser()
  const [userNavToggle, setUserNavToggle] = useState(false)
  const [display, setDisplay] = useState(null)
  const triggerRef = useRef(null)
  const isSSR = useIsSSR()
  const { t } = useTranslation()
  const { trips, nextSlice, upcomingTrips, inProgressTrips } = useTrips({
    user,
  })
  const { isMobile, isTablet, isMediumDesktop } = useBreakpoints()
  const nextTrip = trips?.find((T) => T.id === nextSlice?.tripId)
  const partner = getPartnershipsCookie()
  const displayBanner = useFilteredConstants().apply(DISPLAY_BANNER)

  useEffect(() => {
    const handleRouteChange = () => {
      setUserNavToggle(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [userNavToggle])

  useEffect(() => {
    if (!userNavToggle) {
      document.body.classList.remove('overflow-hidden')
      setDisplay(null)
    } else {
      document.body.classList.add('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  })

  useScroll({ isDisabled: !userNavToggle })

  const disconnect = () => {
    api.auth.logout().then(() => {
      track('account_logout', { userId: user?.id })
      mutateUser(null, false)
      router.push('/')
    })
  }

  const connect = () => {
    // the search may be affected if the user is connected
    if (router.pathname.startsWith('/search')) {
      router.reload()
    }
  }

  return (
    <>
      <nav className={clsx('absolute top-0 w-full z-20 pb-20', className)}>
        {router.locale == 'fr' &&
          ['/', '/gift'].includes(router.pathname) &&
          isXMAS() && (
            <section className="relative w-full">
              <ChristmasBanner path={router.pathname}>
                Dernière ligne droite pour vos cadeaux de Noël 🎅🏻
              </ChristmasBanner>
            </section>
          )}

        {MAINTENANCE && displayBanner ? (
          <Banner>{t('components.navigation.maintenance')}</Banner>
        ) : (
          <>
            {DISPLAY_APP_BANNER.includes(router.pathname) &&
              !displayBanner &&
              !DISPLAY_APP_BANNER_PARTNER_EXCLUSION.includes(partner?.name) && (
                <DownloadAppBanner />
              )}
            {displayBanner && (
              <section className="relative w-full">
                <CarBanner path={router.pathname}>
                  Et on loue aussi des voitures : on a sélectionné, pour vous,
                  les meilleurs loueurs !
                </CarBanner>
              </section>
            )}
          </>
        )}
        <div className="container pt-20">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-10">
              <Link
                href="/"
                className={`h-40 flex items-center ${
                  isDark ? 'text-white' : 'text-dark'
                }`}
                aria-label="Ulysse"
              >
                <FullLogo />
              </Link>
              {showTrustpilot && (
                <TrustpilotScore
                  className={clsx(
                    'self-center mt-10',
                    isDark ? 'text-white' : ''
                  )}
                  hideReviews={isMobile}
                />
              )}
            </div>
            {children}
            <div className="flex items-center">
              {router.route === '/' && (
                <>
                  <Link
                    href="/gift"
                    className="rounded-full ring-dark-10 motion-safe:transition motion-safe:duration-300 ease-out mr-15"
                    aria-label={t('components.navigation.giftCards')}
                  >
                    <div className="flex items-center rounded-full text-white font-bold h-40 motion-safe:transition motion-safe:duration-300 ease-out">
                      <span className="text-14 leading-none">
                        {t('components.navigation.giftCards')}
                      </span>
                    </div>
                  </Link>
                  {isFlightClub && WHATSAPP_URL !== null ? (
                    <Link
                      href={
                        WHATSAPP_URL +
                        '?text=' +
                        encodeURI(t('prioritySupport.defaultText'))
                      }
                      className="rounded-full bg-white ring-1 ring-dark-10 motion-safe:transition motion-safe:duration-300 ease-out mr-15"
                      target="_blank"
                      aria-label={t(
                        'components.navigation.prioritySupport'
                      ).replace(/(<([^>]+)>)/gi, '')}
                    >
                      <div className="flex items-center rounded-full text-dark-80 h-40 motion-safe:transition motion-safe:duration-300 ease-out">
                        <Whatsapp
                          width={24}
                          height={24}
                          className="text-dark-40"
                        />
                        {!(isMobile || isTablet || isMediumDesktop) ? (
                          <span className="text-14 leading-[18px] mx-10">
                            <Trans
                              i18nKey="components.navigation.prioritySupport"
                              components={{
                                b: <p className="font-bold" />,
                              }}
                            />
                          </span>
                        ) : null}
                      </div>
                    </Link>
                  ) : !(isMobile || isTablet) && router.locale == 'fr' ? (
                    <Link
                      href="/ancv-connect"
                      className="rounded-full text-white font-bold motion-safe:transition motion-safe:duration-300 ease-out mr-15"
                      aria-label={t('components.navigation.flightClub').replace(
                        /(<([^>]+)>)/gi,
                        ''
                      )}
                    >
                      <div className="flex items-center rounded-full h-40 motion-safe:transition motion-safe:duration-300 ease-out">
                        <span className="text-14 leading-[18px]">
                          <Trans
                            i18nKey="components.navigation.ancv"
                            components={{
                              b: <p className="font-bold" />,
                            }}
                          />
                        </span>
                      </div>
                    </Link>
                  ) : null}
                  {!isMobile &&
                  (inProgressTrips.length || upcomingTrips.length) ? (
                    <DropDown
                      overlayClassName="ulysse-navigation-next-trip"
                      timeout={300}
                      trigger={({ isOpen }) => (
                        <Button
                          unstyled
                          className={clsx(
                            'block text-white font-bold mr-15 border-b-2 border-transparent',
                            {
                              'border-white': isOpen,
                            }
                          )}
                          type="button"
                          href="/trips"
                        >
                          <div
                            className={clsx(
                              'motion-safe:transition motion-safe:duration-300 ease-out'
                            )}
                          >
                            <span className="text-14 font-bold leading-none">
                              {t('components.navigation.myTrips')} (
                              {inProgressTrips.length + upcomingTrips.length})
                            </span>
                          </div>
                        </Button>
                      )}
                      placement="bottom right"
                    >
                      {({ transitionState }) => (
                        <div
                          className={clsx(
                            'relative transform mt-10 ease-in duration-300 origin-top-right outline-0',
                            {
                              'opacity-100 translate-y-0':
                                transitionState == 'entering' ||
                                transitionState == 'entered',
                              'opacity-0 -translate-y-50':
                                transitionState == 'exiting' ||
                                transitionState == 'exited',
                            }
                          )}
                        >
                          <div className="w-10 h-10 bg-white rotate-45 -translate-y-3 absolute z-px right-25" />
                          <NextTrip
                            trip={nextTrip}
                            slice={nextSlice}
                            transitionState={transitionState}
                          />
                        </div>
                      )}
                    </DropDown>
                  ) : (
                    !isMobile && (
                      <Link
                        href="/trips"
                        className="rounded-full text-white font-bold motion-safe:transition motion-safe:duration-300 ease-out mr-15"
                        aria-label={t('components.navigation.myTrips').replace(
                          /(<([^>]+)>)/gi,
                          ''
                        )}
                      >
                        <div className="flex items-center rounded-full h-40 motion-safe:transition motion-safe:duration-300 ease-out">
                          <span className="text-14 leading-[18px]">
                            {t('components.navigation.myTrips')}
                          </span>
                        </div>
                      </Link>
                    )
                  )}
                </>
              )}

              {!isMobile && router.locale === 'fr' && router.route === '/' && (
                <DropDown
                  overlayClassName="ulysse-navigation-organise-trip z-[20]"
                  timeout={100}
                  trigger={({ isOpen }) => (
                    <Button
                      unstyled
                      className={clsx(
                        'block text-white -mb-1 font-bold mr-15 border-b-2 border-transparent',
                        {
                          'border-white': isOpen,
                        }
                      )}
                      type="button"
                    >
                      <div
                        className={clsx(
                          'motion-safe:transition motion-safe:duration-200 ease-out'
                        )}
                      >
                        <span className="text-14 font-bold leading-none">
                          Organiser mon voyage
                        </span>
                      </div>
                    </Button>
                  )}
                  placement="bottom right"
                >
                  {({ transitionState }) => (
                    <div
                      className={clsx(
                        'relative z-20 transform mt-10 ease-in duration-200 origin-top-right outline-0',
                        {
                          'opacity-100 translate-y-0':
                            transitionState == 'entering' ||
                            transitionState == 'entered',
                          'opacity-0 -translate-y-20':
                            transitionState == 'exiting' ||
                            transitionState == 'exited',
                        }
                      )}
                    >
                      <div className="w-10 h-10 bg-white rotate-45 -translate-y-3 absolute z-px right-25" />
                      <OrganiseTrip />
                    </div>
                  )}
                </DropDown>
              )}
              <Trigger
                ref={triggerRef}
                onClick={() => {
                  setUserNavToggle(true)
                }}
                userNavToggle={userNavToggle}
                aria-label={t('components.navigation.openMenu')}
                mode={isDark ? 'white' : 'dark'}
              />
            </div>
          </div>
        </div>
      </nav>
      {!isSSR && userNavToggle ? (
        <OverlayContainer>
          <Menu
            userNavToggle={userNavToggle}
            setUserNavToggle={setUserNavToggle}
            display={display}
            setDisplay={setDisplay}
            disconnect={disconnect}
            connect={connect}
            triggerRef={triggerRef}
            nextTrip={nextTrip}
            nextSlice={nextSlice}
          />
        </OverlayContainer>
      ) : null}
    </>
  )
}

function NextTrip({ trip, slice, transitionState }) {
  const { formatDate } = useDates()
  const { t } = useTranslation()

  return (
    <Link
      href={`/trips/${trip.id}`}
      className="group bg-white flex items-end md:w-280 rounded shadow-double outline-none"
    >
      <div
        className={clsx(
          'w-90 flex-shrink-0 h-75 -ml-15 -mb-px ease-in transform duration-300',
          {
            'opacity-100 translate-x-0': transitionState == 'entering',
            'opacity-0 translate-x-50':
              transitionState == 'exiting' || transitionState == 'exited',
          }
        )}
      >
        <Image src={LugageSm} alt="" />
      </div>
      <div className="py-20 pr-20 flex items-end grow">
        <div className="grow">
          <p className="text-18 text-primary font-bold">
            {t('components.nextTrip.title', {
              origin: slice.origin.city_name,
              destination: slice.destination.city_name,
            })}
          </p>
          <p className="text-14 text-dark-60">
            {t('components.nextTrip.desc', {
              departureDate: formatDate(slice.departureLocalDate, 'PP'),
              count: trip.passengers.length,
            })}
          </p>
        </div>
        <Arrow
          className={clsx(
            'ml-5 flex-shrink-0 text-primary transform duration-300 ease-in',
            {
              'opacity-100 translate-x-0': transitionState == 'entering',
              'opacity-0 -translate-x-50':
                transitionState == 'exiting' || transitionState == 'exited',
            }
          )}
        />
      </div>
    </Link>
  )
}

export default NavigationComponent
